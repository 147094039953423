html,
body,
#root {
  width: 100%;
  height: 100%;
}

.MuiStepLabel-label {
  color: #fff !important;
}

.MuiStepIcon-root {
  background-color: #fff;
  border-radius: 50%;
  overflow: inherit;
}

.MuiDrawer-root {
  .MuiPaper-root {
    border-color: #fff !important;
  }

  .MuiDivider-root {
    background-color: #fff!important;
  }
}

.MuiOutlinedInput-input {
  padding: 8px 14px!important;
}

.MuiButton-root {
  text-transform: none!important;
}

.mentions_mentions__3PzrD {
  margin: 0!important;
  border-color: #999999!important;
  border-radius: 4px;
}

.MuiInputBase-input {
  font-size: 14px!important;
}
